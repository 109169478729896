<template>
  <div class="evgenia">
    <div class="img-block mb-14">
      <picture class="block w-full">
        <img
          src="/imgs/content/photos/new_evgenia_gruzdeva_bio_photo.jpg"
          alt="Евгения Груздева"
          class="block w-full"
        />
      </picture>
    </div>
    <section class="mb-14">
      <div class="container">
        <h2 class="text-4xl font-pt-mono mb-4" v-html="$t('founder_name_br')"></h2>
        <p class="global-text-sm text-sm mb-8">{{ $t("founder_and_permanent") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("title_start") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article1") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article2") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("article3") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article4") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article5") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article6") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("article7") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article8") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article9") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article10") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("article11") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article12") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("article13") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article14") }}</p>
        <p class="global-text-sm text-sm mb-8">
          <b>{{ $t("article15") }}</b>
        </p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article16") }}</p>
        <p class="global-text-sm text-sm mb-8">{{ $t("article_final") }}</p>
      </div>
    </section>
  </div>
</template>
